<template>
  <div>
    <div class="top-banner">
      <div class="banner-text">
        <div class="banner-title">大象流程引擎</div>
        <div class="banner-subtitle">
          大象流程引擎具备完整的业务流程设计器及流程管理能力，同时具备表单引擎、权限管理、系统集成等功能，为业务功能快速开发提供技术技术支撑。本产品遵循行业规范，具备良好的易用性、开放性、安全性，可以快速对接三方系统，有效提升人和组织的生产力、提升管理精细度。
        </div>
      </div>
    </div>
    <div class="process">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            规范且易用的流程设计功能，助力政企建立智能高效的数字化流程管理体系
          </div>
          <div class="dot-plot">流程中心</div>
        </div>
        <div class="process-main">
          <div>
            <div v-for="item in processList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner1.png" alt="" />
        </div>
      </div>
    </div>

    <div class="engine">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            基于丰富的组件库, 可视化升级生成业务表单
          </div>
          <div class="dot-plot">表单引擎</div>
        </div>
        <div class="engine-main">
          <img src="@/assets/images/banner2.png" alt="" />
          <div>
            <div v-for="item in engineList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="process task">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            功能完整的任务推送和提醒机制, 赋能高效的工作任务处理
          </div>
          <div class="dot-plot">任务中心</div>
        </div>
        <div class="process-main task-main">
          <div>
            <div v-for="item in taskList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner3.png" alt="" />
        </div>
      </div>
    </div>

    <div class="engine systems">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            良好的开放性, 连接多业务系统的业务和数据, 实现业务闭环
          </div>
          <div class="dot-plot">系统集成</div>
        </div>
        <div class="engine-main system-main">
          <img src="@/assets/images/banner4.png" alt="" />
          <div>
            <div v-for="item in systemList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="type-area">
        <div class="divider">
          <el-divider>系统优势</el-divider>
        </div>
        <div class="advantage-main">
          <div class="item" v-for="item in advantageList">
            <img :src="item.img" alt="" />
            <span>{{ item.title }}</span>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage scenes">
      <div class="type-area">
        <div class="divider">
          <el-divider>应用场景</el-divider>
        </div>
        <div class="advantage-main scenes-main">
          <div
            class="item"
            v-for="item in scenesList"
            :style="{ backgroundImage: `url(${item.bgimg})` }"
          >
            <div class="scenes-title">
              <span>{{ item.title }}</span>
              <div>{{ item.subTitle }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact_us">
      <div class="type-area">
        <span>请联系我们, 您将获得全程支持咨询服务</span>
        <el-button type="primary">联系我们</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { caseTag, caseGet } from "@/api/request";

export default {
  name: "tech", // 开放能力
  data() {
    return {
      processList: [
        {
          title: "可视化设计",
          subTitle: "支持Web端拖拽方式进行流程设计, 实现流程节点的属性配置",
        },
        {
          title: "多种节点组件",
          subTitle:
            "覆盖各类流程场景, 包括任务、子流程、网关、边界事件、结束事件等环节",
        },
        {
          title: "集成系统用户",
          subTitle:
            "默认对接系统组织结构, 创建用户任务时可指派到角色、具体用户",
        },
        {
          title: "绑定表单",
          subTitle: "支持已经发布的流程实例绑定表单, 形成完整的业务功能",
        },
      ],
      engineList: [
        {
          title: "表单可视化设计",
          subTitle: "支持Web端拖拽各类型控件形成表单, 快速实现业务功能",
        },
        {
          title: "字段属性定义",
          subTitle:
            "结合业务场景对各字段进行灵活配置, 包括显示样式、操作属性等",
        },
        {
          title: "表单发布",
          subTitle:
            "表单编辑提交后可以发布成为正式表单, 支持保存数据、开启流程等操作",
        },
      ],
      taskList: [
        {
          title: "任务列表",
          subTitle:
            "列表形式显示当前用户的任务, 支持处理任务、查看流程、查看办理记录",
        },
        {
          title: "组任务",
          subTitle:
            "用户任务分配至用户组时, 系统自动将任务推送至同一用户角色所有用户",
        },
        {
          title: "消息推送",
          subTitle:
            "新任务流转至用户时支持以多种消息形式提醒, 支持消息模版自定义",
        },
      ],
      systemList: [
        {
          title: "业务系统对接",
          subTitle: "提供多异构系统提供集成能力, 如ERP、OA、财务、客服等系统",
        },
        {
          title: "API接口开放",
          subTitle: "提供标准的API接口，打破孤岛, 连接内外部系统的业务和数据",
        },
        {
          title: "API接口网关",
          subTitle:
            "实时监控接口调用情况, 具备熔断、降级、限流、服务路由等功能",
        },
      ],
      advantageList: [
        {
          title: "集成便捷",
          content:
            "支持页面嵌入式集成, 组织架构快速对接, 跨域标准Restful API接口",
          img: require("@/assets/images/icon_jichengbianjie.png"),
        },
        {
          title: "支持国产化",
          content: "适配国产达梦数据库、麒麟操作系统, 适应中国软件生态环境",
          img: require("@/assets/images/icon_zhichiguochan.png"),
        },
        {
          title: "支持多终端",
          content: "支持PC端、移动端 (原生APP、H5、微信、钉钉)、平板PAD等终端",
          img: require("@/assets/images/icon_zhichiduozhongduan.png"),
        },
        {
          title: "灵活复用",
          content:
            "支持同一流程可以被多个表单使用, 同一表单可以被多个流程定义使用",
          img: require("@/assets/images/icon_linghuofuyong.png"),
        },
        {
          title: "符合规范",
          content: "基于BPMN2.0行业规范开发, 支持和已有系统流程规范接口对接",
          img: require("@/assets/images/icon_fuheguifan.png"),
        },
        {
          title: "支持多租户",
          content: "支持划分为多个租户管理, 同一个数据库层面实现数据逻辑隔离",
          img: require("@/assets/images/icon_zhichiduozuhu.png"),
        },
        {
          title: "支持多业务系统",
          content:
            "分布式架构模式, 以独立的微服务方式运行, 各业务系统可以直接调用",
          img: require("@/assets/images/icon_zhichiduoyewu.png"),
        },
        {
          title: "安全可靠",
          content:
            "基于JWT安全机制, 具备完善的安全防护能力, 确保系统通信和运行安全性",
          img: require("@/assets/images/icon_anquankekao.png"),
        },
      ],
      scenesList: [
        {
          title: "统一流程管理",
          subTitle: "统一数据统一管理",
          bgimg: require("@/assets/images/banner5.png"),
        },
        {
          title: "任务工作台",
          subTitle: "连接企业各业务板块管理",
          bgimg: require("@/assets/images/banner6.png"),
        },
        {
          title: "移动端审批",
          subTitle: "嵌入工作流活化流程",
          bgimg: require("@/assets/images/banner7.png"),
        },
        {
          title: "系统管理中台",
          subTitle: "连接企业各业务板块管理",
          bgimg: require("@/assets/images/banner8.png"),
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
// .type-area {
//   width: 1180px;
//   margin: 0 auto;
// }
.dot-plot {
  width: 380px;
  height: 137px;
  text-align: right;
  background: url("~@/assets/images/dot-plot.png") no-repeat;
  background-size: 380px 137px;
  padding-right: 16px;
  padding-top: 36px;
  font-size: 28px;
  line-height: 28px;
}
.divider {
  padding: 0px 250px;
  & /deep/.el-divider__text {
    font-size: 36px;
    font-weight: 400;
    color: #17191f;
    line-height: 36px;
    padding: 0 52px;
    background-color: #fbfbfb;
  }
  &/deep/.el-divider {
    background-color: #d1d6e0;
    height: 1px;
  }
}
.top-banner {
  height: 580px;
  width: 100%;
  background-image: url("~@/assets/images/banner.png");
  background-color: #010d14;
  background-size: 1800px 580px;
  background-position: top;
  background-repeat: no-repeat;
  color: #ffffff;
  overflow: hidden;
  .banner-text {
    width: 1180px;
    margin: 0 auto;
  }
  .banner-title {
    font-size: 60px;
    font-weight: 400;
    line-height: 82px;
    margin-top: 166px;
  }
  .banner-subtitle {
    font-size: 16px;
    line-height: 30px;
    margin-top: 27px;
    width: 454px;
    height: 90px;
    color: #B6BFD3;
  }
}
.base-top {
  height: 118px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:first-child {
    display: flex;
    width: 654px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    font-size: 38px;
    line-height: 59px;
    font-weight: 300;
    text-align: justify;
    .cut-off {
      background: #108aee;
      height: 4px;
      width: 52px;
    }
  }
}
.process {
  background: #f5f7f9;
  height: 931px;
  padding-top: 91px;

  .process-main {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;
    img {
      width: 709px;
      height: 517px;
    }
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        margin-top: 58px;
        font-size: 16px;
        font-weight: 400;
        color: #797c80;
        line-height: 26px;
        &:first-child {
          margin-top: 30px;
        }
        span {
          color: #1e1e1e;
          font-weight: 600;
          line-height: 20px;
          font-size: 20px;
          margin-bottom: 22px;
        }
      }
    }
  }
}

.engine {
  padding-top: 100px;
  height: 819px;
  .engine-main {
    display: flex;
    justify-content: space-between;
    margin-top: 110px;
    img {
      width: 680px;
      height: 390px;
    }
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        margin-top: 58px;
        font-size: 16px;
        font-weight: 400;
        color: #797c80;
        line-height: 26px;
        &:first-child {
          margin-top: 0;
        }
        span {
          color: #1e1e1e;
          font-weight: 500;
          line-height: 20px;
          font-size: 20px;
          margin-bottom: 22px;
        }
      }
    }
  }
}

.task {
  height: 803px;
  .task-main {
    img {
      width: 667px;
      height: 429px;
    }
  }
}
.systems {
  height: 976px;
  .system-main {
    margin-top: 58px;
    align-items: center;
    img {
      width: 735px;
      height: 605px;
      margin-left: -45px;
    }
  }
}
.advantage {
  height: 799px;
  background: #fbfbfb;
  padding-top: 84px;
  &/deep/.el-divider__text{
    background:#fbfbfb;
  }
  .advantage-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -21px;
    .item {
      display: flex;
      flex-direction: column;
      width: 265px;
      height: 200px;
      margin-top: 107px;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
      }
      span {
        font-size: 20px;
        font-weight: 500;
        color: #1e1e1e;
        line-height: 20px;
        margin: 12px 0 17px 0;
      }
      div {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: rgba(30, 30, 30, 0.67);
        line-height: 28px;
      }
    }
  }
}
.scenes {
  height: 655px;
  padding-top: 80px;
  background: #fff;
  &/deep/.el-divider__text{
    background:#fff;
  }
  .scenes-main {
    margin-top: 84px;
    .item {
      width: 273px;
      height: 338px;
      margin-top: 0;
      padding: 211px 12px 0;
      cursor: pointer;
      background-size: cover;
      &:hover {
        box-shadow: 0px 9px 24px 0px rgba(22, 46, 111, 0.2);
      }
      .scenes-title {
        width: 249px;
        height: 115px;
        padding: 30px 20px;
        background: #fff;
        span {
          color: #0f80dc;
          font-size: 22px;
          line-height: 30px;
          font-weight: 500;
        }
        div {
          color: #797c80;
          font-size: 14px;
          line-height: 20px;
          margin-top: 6px;
          font-weight: 400;
        }
      }
    }
  }
}
.contact_us {
  height: 289px;
  background: #f6f7f9;
  .type-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 88px;
  }
  span {
    font-size: 28px;
    line-height: 28px;
    color: #393c40;
    font-weight: 500;
    margin-bottom: 33px;
  }
  &/deep/.el-button {
    width: 161px;
    height: 56px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 0;
    background: #0f80dc;
    border-color: #0f80dc;
    &:hover{
      background: #108AEE;
    border-color: #108AEE;
    }
  }
}
</style>
